<template lang="html">
  <div class="">

    <div  v-if="this.loading"  class="loading_outside" >
    </div>

    <div  v-if="this.loading"  class="loading">
             <div :style="{width:'80%', marginLeft:'10%', marginTop:'3%'}">
                Skapar och loggar in på ditt konto ... <br> Vänligen vänta...det kan ta några minuter
              </div>
    </div>


    <div class="welcome">
      Välkommen att registrera dig på Enjord
    </div>

     <div class="create_account"  >
      Skapa ett konto på Enjord genom att fylla i information nedan och sedan klicka "Skapa företagskonto" längst ned
     </div>

  <div  class="register" >
    <form name="register">

      <div class="register_header">
        <div class="information">
          Kontoinformation
        </div>
        Fyll i kontoinformation så att du kan logga in
      </div>
      <div class="box_main" >
        <div class="information_small">
          Kontoinformation
          <span :style="{color:'red'}">*</span>
        </div>
        <div :style="{height:'20px',fontSize:'13px', marginLeft:'4%', color:'black'}">
          <em>Fyll i konto/login information nedan</em>
        </div>
         <input type="text" maxlength="500" placeholder="Email" class="input_field" @change='check_email_exists()'  v-model.trim="login_email" >
         <input type="password" maxlength="500" placeholder="Lösenord" class="input_field"     v-model="login_password" >
      </div>


      <div class="register_header">
        <div class="information">
          Företagsinformation
        </div>
        <p>Fyll in information om ditt företag </p>
      </div>


      <div class="box_main">
        <div class="information_small">
          Ert företagsnamn<span :style="{color:'red'}">*</span></div>
          <input type="text" placeholder="Fyll i företagets namn"   class="input_field"    v-model.trim="company_name" >
      </div>


      <div class="box_main">
        <div class="information_small">
          Er företagsinfo
          <span :style="{color:'red'}">*</span></div>
        <div :style="{height:'20px',fontSize:'13px', marginLeft:'4%', color:'black'}">
          <em>Fyll i företagsinfo</em>
        </div>
          <textarea type="text" placeholder="Fyll i en kort beskrivning av ditt företag "   class="input_field_l"     v-model="company_info" />
      </div>

      <div class="box_main">
        <div class="information_small">
          Vad har ni för priser?
        </div>
        <div :style="{height:'20px',fontSize:'13px', marginLeft:'4%', color:'black'}">
          <em>
            Era priser
          </em>
        </div>
          <textarea type="text"   placeholder="Fyll i era priser"   class="input_field_l"     v-model="price" />
      </div>

      <div class="box_main" >
        <div class="information_small">
          Vad erbjuder ni för tjänster?
        </div>
        <div :style="{height:'20px',fontSize:'13px', marginLeft:'4%', color:'black'}">
          <em>
            Era tjänster
          </em>
        </div>
          <textarea type="text"   placeholder="Fyll i era tjänster"   class="input_field_l"     v-model="our_services" />
      </div>

      <div class="box_main">
        <div class="information_small">
          Vad har ni för öppettider?
        </div>
        <div :style="{height:'20px',fontSize:'13px', marginLeft:'4%', color:'black'}">
          <em>
            Era öppettider
          </em>
        </div>
          <textarea type="text"   placeholder="Fyll i era öppettider"   class="input_field_l"
           v-model="our_opening_hours" />
      </div>


      <div class="box_main">
        <div class="information_small">
          Övrig information
          </div>
        <div :style="{height:'20px',fontSize:'13px', marginLeft:'4%', color:'black'}">
          <em>Fyll i ytterligare information nedan</em>
        </div>

         <input type="text" maxlength="500" placeholder="Företagets hemsida" class="input_field"     v-model="website" >
         <input type="text" maxlength="500" placeholder="Företagets email" class="input_field"     v-model="contact_email" >
         <input type="text" maxlength="500" placeholder="Företagets telefon" class="input_field"     v-model="phone_number" >


         <!-- <input type="text" class="search_ruta_input" v-model="search_country"  placeholder="Land" v-on:keyup="search_country_func(search_country)" >
         <div :style="{marginTop:'1%', marginLeft:'3%',width:'90%',maxHeight:'110px', overflowY:'auto', borderStyle:'None', borderWidth:'0.5px'}">
           <div  :style="{height:'20px', fontSize:'15px',width:'88%', display:'flex'}" v-for="(country,idx) in this.filtered_country_list" :key="idx">
             <input
               type="checkbox"
               :id="'country'+country.id"
               :class="country.checked ? 'checkbox_filled' : 'checkbox_new'"
               v-model="country.checked"
               @click="choose_country(country)"
             />
             <label :for="'country'+country.id">{{ country.country }} </label>
           </div>
         </div> -->
      </div>


      <div  class="box_main">
        <div :style="{marginLeft:'4%',color:'#0A6338', fontSize:'18px',paddingTop:'2%'}">
          Var finns ni?<span :style="{color:'red'}">*</span>
        </div>
        <div :style="{height:'30px',fontSize:'13px', marginLeft:'4%',display:'flex', color:'black'}">
          <em>
            Leverar ni till hela landet så klicka i denna box
           </em>
           <input type="checkbox" v-model="online_service" class="checkbox_new" value="">
        </div>


        <div class="information_location" >
          <em>Ge information om var ni finns (om ni har butiksadress så fyll gärna i det alternativt fyll i de städer/län där ni är verksamma ) <br>
          Klicka på "Lägg till" för att få med platsen i listan </em>
        </div>

        <input type="text" maxlength="500" placeholder="Address" class="input_field"     v-model="adress" >
        <input type="text" maxlength="500" placeholder="Postkod" class="input_field"    v-model="city_code" >
        <!-- <input type="text" maxlength="500" placeholder="Stad" class="input_field"     v-model="city" > -->


        <input type="text" class="search_ruta_input" v-model="search_lan"
         placeholder="Sök stad"
         v-on:keyup="search_city_func(search_lan)" >
        <div :style="{marginTop:'1%', marginLeft:'3%',width:'90%',maxHeight:'154px', overflowY:'auto', borderStyle:'None', borderWidth:'0.5px'}">
          <div class="search_city_v_for"
          v-for="(city,idx) in this.filtered_city_list" :key="idx">
            <input
              type="checkbox"
              :id="'lan'+city.id"
              :class="city.checked ? 'checkbox_filled' : 'checkbox_new'"
              v-model="city.checked"
              @click="choose_city2(city)"
            />
            <label :for="'lan'+city.id">{{ city.city }} [ {{city.lan}}] </label>
          </div>
        </div>
        <div :style="{borderStyle:'solid', borderWidth:'1px', borderColor:'black', marginTop:'1%',marginLeft:'4%',width:'90%'}">

        </div>
        <button :style="{marginTop:'2%',marginLeft:'2%'}" @click="add_location()" type="button" name="button">Lägg till </button>

        <div :style="{width:'90%', height:'90px',marginLeft:'4%', overflowX:'auto'}">
          <div  :style="{  display: 'flex',
              width: '90%',  justifyContent: 'flexStart',fontWeight: 'bold'  }">
              <!-- <div class="lead_width_header">Butik</div> -->
              <div class="lead_width_header">Adress</div>
              <div class="lead_width_header">Postkod</div>
              <div class="lead_width_header">Kommun</div>
              <div class="lead_width_header">Län</div>
          </div>
          <div :style="{   display: 'flex',   width: '90%',
              borderBottom: '0.1px grey solid', justifyContent: 'flexStart'}"  v-for="(adress,idx) in this.added_adress_list" :key="idx">
              <div class="lead_width">
                {{ adress.adress }}
              </div>
              <div class="lead_width">
                {{ adress.city_code }}
              </div>
              <div class="lead_width">
                {{ adress.city }}
              </div>
              <div class="lead_width_left">
                {{ adress.lan }}
              </div>
              <div class="lead_width">
                <b-icon     icon="x-lg"         :style="{ marginTop: '3%' }"
                  @click="remove_location(adress)"
                />
              </div>
          </div>
        </div>
        <div :style="{marginLeft:'3%', fontSize:'14px'}">
        <em>  Swajpa för att se hela adressen </em>
        </div>

      </div>

      <div class="box_main">
        <div :style="{marginLeft:'4%', color:'#0A6338', fontSize:'18px', paddingTop:'2%'}">
          Kategori
          <span :style="{color:'red'}">*</span>
        </div>
        <div :style="{height:'10px',fontSize:'13px', marginLeft:'4%', color:'black'}">
          <em>Vilken kategori tillhör du? (Fyll i alla som passar in)</em>
        </div>
        <input type="text" class="search_ruta_input" v-model="search_category"  placeholder="Sök kategori" v-on:keyup="search_category_func(search_category)"  >
        <div :style="{marginTop:'1%', marginLeft:'3%',width:'88%',maxHeight:'226px', overflowY:'auto'}">
          <button v-if="!all_selected_category" type="button" name="button" :style="{ marginLeft:'1.4%',paddingBottom:'0%', borderStyle:'None',color:'white', borderRadius:'8px',backgroundColor:'#0A6338',marginTop:'0%'}" @click="choose_all_category()">Markera alla</button>
          <button v-if="all_selected_category" type="button" name="button" :style="{ marginLeft:'1.4%',paddingBottom:'0%', borderStyle:'None',color:'white', borderRadius:'8px',backgroundColor:'#0A6338',marginTop:'0%'}" @click="choose_all_category()">Avmarkera alla</button>
          <div :style="{height:'5px'}">
          </div>

          <div :style="{height:'20px', fontSize:'14px',width:'80%', display:'flex'}" v-for="(category,idx) in this.filtered_category_list" :key="idx">
            <input
              type="checkbox"
              :id="'cat'+category.id"
              :class="category.checked ? 'checkbox_filled' : 'checkbox_new'"
              v-model="category.checked"
              @click="choose_category(category)"
            />
            <label  :style="{textIndent:'5px'}" :for="'cat'+category.id">{{ category.category }}  </label>
          </div>
        </div>
      </div>

      <div  class="box_main">
        <div class="information_small">Märken ni lagar  </div>
        <div class="information_location" >
          <em>Vilka märken jobbar ni med?  (om du inte hitter det du söker så lägg gärna till det i listan) </em>
        </div>
          <input type="text" class="search_ruta_input" v-model="search_brand"  placeholder="Sök märke"  v-on:keyup="search_brand_func(search_brand)">

            <button type="button" :style="{color:'white',marginTop:'1%',height:'auto',width:'auto',marginLeft:'4%',borderStyle:'None',backgroundColor:'#0A6338', borderRadius:'8px'}"
              @click="add_brand(search_brand)">Lägg till "{{search_brand}}"  i listan</button>

          <div v-if="chosen_brands_list.length !=0" :style="{height:'auto',marginTop:'1%',marginLeft:'3%', flexWrap:'wrap',borderRadius:'0px 18px 18px 18px' ,display:'flex',overflowY:'auto' }">
              <div  v-for="(brands,idx) in this.chosen_brands_list" :key="idx" class="box_flow_key">
                <div @click="remove_brand(brands)" :style="{ cursor:'pointer',marginLeft:'92%',width:'50px',height:'7px',marginTop:'0%', fontSize:'12px'}">
                  X
                </div>
                <div :style="{textAlign:'center',marginTop:'1%'}">
                  {{brands.brand}}
                </div>
              </div>
          </div>

          <div :style="{marginTop:'1%', marginLeft:'3%',width:'90%',maxHeight:'230px', overflowY:'auto', borderStyle:'None', borderWidth:'0.5px'}">
            <div :style="{height:'20px', fontSize:'14px',width:'100%', display:'flex'}"
                  v-for="(brand,idx) in this.filtered_brands_list" :key="idx">
              <input  type="checkbox"  :id="'key'+brand.id"
                :class="brand.checked ? 'checkbox_filled' : 'checkbox_new'"
                v-model="brand.checked" @click="choose_brand(brand)"/>
              <label :style="{textIndent:'5px'}" :for="'key'+brand.id">{{ brand.brand }}</label>
            </div>
          </div>
      </div>



      <!-- <div :style="{width:'90%', marginLeft: '5%',height:'100%',minHeight:'263px',marginTop:'3%', borderRadius:'18px', backgroundColor:'white', boxShadow:' 0px 3px 30px #0000001A'}">
        <div :style="{marginLeft:'4%', color:'#0A6338', fontSize:'18px', paddingTop:'2%'}">Produkter och tjänster <span :style="{fontStyle:'italic', fontSize:'12px'}">(Frivillig)</span></div>
        <div :style="{height:'8px',fontSize:'13px', marginLeft:'4%', color:'black'}">
          <em>Vilka produkter och tjänster tillhandahåller du? </em>
        </div>
        <div :style="{height:'155px',marginLeft:'6%',paddingBottom:'2%',marginLeft:'1%',marginTop:'5%',color:'black', fontSize:'14px',width:'80%', borderRadius:'8px', borderWidth:'2px', borderColor:'#F5F5F5'}"
        v-for="(product,idx) in this.product_list" :key="idx">
          <div @click="remove_from_product_list(product.id)" :style="{marginTop:'1%',position:'absolute',fontWeight:600, marginLeft:'59%',fontSize:'20px',color:'#0A6338'}">
              <b-icon  icon="x-lg" ></b-icon>
          </div>
          <div :style="{display:'flex',marginTop:'1%', height:'35px', paddingBottom:'0%'}" v-for="(p,idx) in product.list" :key="idx">
            <input v-if="p.label=='Name'" type="text" maxlength="500"  :placeholder="p.label_sve + ' av produkt/tjänst'" class="input_field"  v-model="p.name" />
            <textarea v-if="p.label=='Description'" type="text"   :placeholder="p.descr_sve + ' of produkt/tjänst'" class="input_field_l"  v-model="p.name" />
          </div>
        </div>
        <button @click="add_more_to_product_list()"  :style="{ marginLeft:'4%', borderStyle:'None',color:'white', borderRadius:'8px',backgroundColor:'#0A6338',marginTop:'2%'}" type="button" name="button">Lägg till fler produkter</button>
        <div :style="{height:'20px'}">
        </div>
      </div> -->

    </form>
    <div class="box_main_image">
      <div :style="{marginLeft:'4%', fontSize:'20px', paddingTop:'2%'}">
        Ladda upp er företagslogga <span :style="{color:'red'}">*</span> </div>
      <img id="output" v-if="this.image !=''" class="show_chosen_image_first"   alt="">
      <label for="upload" :style="{marginLeft:'47%'}" >
        <img :src= "`${publicPath}`+ 'upload.png'" class="addphoto_camera"  alt="" >
      </label>
      <input type="file"  :style="{marginLeft:'15%'}"  id="upload" class="custom-file-upload"  @change="upload_picture">
    </div>
<!--
this.added_adress_list.length ==0 ||
&& this.added_adress_list.length !=0
-->

    <button @click="upload_company()"
      :disabled=" this.login_email=='' || this.login_password=='' ||  this.company_name=='' || this.company_info==''  ||  this.chosen_category_list ==0 ||   this.image ==''   "
      :class="[ this.login_email != '' && this.login_password !='' &&  this.company_name !='' && this.company_info !=''   && this.chosen_category_list !=0 && this.image !=''   ? 'register_button_clicked' : 'register_button' ]"
      >
      Skapa företagskonto
    </button>
    <div :style="{height:'90px'}">
    </div>
</div>



  </div>
</template>

<script>
import router from '../router'
//import Navigation from '../components/Navigation.vue';
 import {API_URL} from '../../config.js'
 import axios from 'axios';

export default {
  data :function(){
  return{
    publicPath: process.env.BASE_URL +'',
    loading:false,
    login_email:'',
    login_password:'',
    company_name:"",
    company_info:'',
    website:'',
    contact_email:'',
    phone_number:'',
    price:"",
    our_services:"",
    online_service:false,
    our_opening_hours:'',
    adress:'',
    city_code:'',
    city:'',
    län:'',
    city_list:[],
    filtered_city_list:[],
    search_lan:"",
    chosen_lan:[],
    filtered_lan_list:[],
    search_country:"",
    chosen_country:[],
    country_list:[],
    filtered_country_list:[],
    chosen_lan_list:[],
    category_list:[],
    chosen_category_list:[],
    search_category:"",
    filtered_category_list:[],
    added_adress_list:[],
    all_selected_category:false,
    product_list_id:1,
    product_list:[],
    image:"",
    selectedFile:"",
    lan_id:0,
    chosen_brands_list:[],
    search_brand:'',
    filtered_brands_list:[],
    brand_query_max:0,
    brands_list:[],
    added_adress_list_max:0,
    full_adress_list:[]
   }
  },
  methods:{
    choose_city2(city_object){
      var checked
      if(!city_object.checked){
        checked =true
        this.city = city_object.city
        this.lan = city_object.lan
        this.lan_id = city_object.lan_id
        // this.search_lan = lan.name
        // this.chosen_lan = [{"lan_id":lan.id,"lan":lan.name}]
      }
      else {
        checked=0
//        this.chosen_lan = []
      }
        for (let ii = 0; ii < this.city_list.length;ii++){
          this.city_list[ii].checked =  false
      }
      this.city_list[city_object.i].checked =  checked
    },
    choose_city(city_object){
      this.filtered_city_list = this.city_list.map(city => {
          if (city.id === city_object.id) {
            //city.checked = city.checked === false ? true : false
            city.checked = !city.checked; // Vänd värdet (true blir false, false blir true) för den aktuella checkboxen
            this.city = city.city
            this.lan = city.lan
            this.lan_id = city.lan_id
          } else {
            city.checked = false; // Sätt alla andra checkboxar till false
          }
          return city;
        });
    },
    choose_lan(lan_object){
      this.län_list = this.län_list.map(lan => {
        if (lan.id == lan_object.id){
            lan.checked = lan.checked === false ? true : false
        }
            return lan
      })
      this.chosen_lan_list = this.län_list.filter(function (lan_obj) {
        if(lan_obj.checked == true) {
          return lan_obj
        }
      });

    },
    choose_lan2(lan){
      var checked
      if(!lan.checked){
        checked =true
        this.search_lan = lan.name
        this.chosen_lan = [{"lan_id":lan.id,"lan":lan.name}]
      }
      else {
        checked=0
        this.chosen_lan = []
      }
        for (let ii = 0; ii < this.län_list.length;ii++){
          this.län_list[ii].checked =  false
      }
      this.län_list[lan.i].checked =  checked
    },
    home(){
      router.push({name:'Home'})
    },
    search_brand_func(search_brand){
          this.filtered_brands_list =  this.brands_list.filter(
            brand_object =>  brand_object.brand.toLowerCase().includes(search_brand.toLowerCase())
          )
    },
    add_brand(brand){
      var i =this.brand_query_max
      this.brand_query_max = this.brand_query_max + 1

      var new_brand = {"i":i,"id":this.brand_query_max ,"brand":brand,"checked":true ,'added':true, 'changed':true }
      this.chosen_brands_list.push(new_brand)

      this.brands_list.push(new_brand)


    },
    remove_brand(brands_obj){
      // function removeItem(arr, id){
      //     return arr.filter(item => item.id !== id);
      // }
      //this.brand_query_max = this.brand_query_max - 1
      //this.chosen_brands_list = removeItem(  this.chosen_brands_list, brands_obj.id);

      this.choose_brand(brands_obj)
      // this.brands_list = this.brands_list.map(brand => {
      //     if (brand.id == brands_obj.id){
      //         brand.added =  false
      //         brand.changed =  false
      //     }
      //         return brand
      //   })
    },
    choose_brand(brand_object){
    this.brands_list = this.brands_list.map(brand => {
        if (brand.id == brand_object.id){
            brand.checked = brand.checked === false ? true : false
            brand.changed=true
        }
            return brand
      })

      this.chosen_brands_list = this.brands_list.filter(function (brand) {
        if(brand.checked == true) {
          return brand
        }
      })
    },

    remove_location(location_obj){
      // function removeItem(arr, id){
      //     return arr.filter(item => item.id !== id);
      // }

      this.added_adress_list = this.full_adress_list.map(location => {
          if (location.id == location_obj.id){
              location.checked =   false
          }
              return location
        })

      this.check_location()

    },
    check_location(){

      this.added_adress_list = this.full_adress_list.filter(function (location) {
        if(location.checked == true) {
          return location
        }
      })

    },
    add_location(){
        this.full_adress_list.push(
          {"id":this.full_adress_list.length, "adress": this.adress, 'checked':true , "city_code":this.city_code, "city":this.city, "lan":this.lan, "lan_id":this.lan_id}
        )


      this.check_location()

    },
    loading_func(){
      if(this.loading){
        this.loading = false
      }
      else {
        this.loading=true
      }
    },
    upload_company(){
      var general = {}
      general["login_email"] = this.login_email


      general["login_password"] = this.login_password
      general["company_name"] = this.company_name
      general["company_info"] = this.company_info
      general["website"] = this.website
      general["contact_email"] = this.contact_email
      general["phone_number"] = this.phone_number
      general["online_service"] = this.online_service


      general["price"] = this.price
      general["our_services"] = this.our_services
      general["our_opening_hours"] = this.our_opening_hours
      general["image"] = this.image

      var location ={}
      location["adress_list"]  =this.added_adress_list


      var category = {}
      category["category"] = this.chosen_category_list

      var brands ={}
      brands["brands"] = this.chosen_brands_list

      // var lan = {}
      // lan["lan"] = this.chosen_lan_list

      this.loading = true

      var self =this
      axios({
          method:'post',
          url: API_URL+ '/uploadCompany',
          data:{general:general,category:category, location:location,brands:brands }
        })
        .then(function(response){
              self.post_to_S3_with_signed_url(response.data)
        })


    },
    load_cities(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_cities',
        })
        .then(function(response){
            self.city_list = response.data
            self.filtered_city_list =self.city_list
      })
    },
    load_brands(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_brands',
        })
        .then(function(response){
            self.brands_list = response.data["show_brand_list"]
            self.brand_query_max =response.data["brand_query_max"]
            self.filtered_brands_list =self.brands_list
      })
    },
    load_lans(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_lan',
        })
        .then(function(response){
            self.län_list = response.data
            self.filtered_lan_list =self.län_list
      })
    },
    load_category(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_all_categories',
        })
        .then(function(response){
            self.category_list = response.data
            self.filtered_category_list =self.category_list
      })
    },
    search_lan_func(search_lan){
        this.filtered_lan_list =  this.län_list.filter(
          lan_object =>  lan_object.lan.toLowerCase().includes(search_lan.toLowerCase())
        )
    },
    search_city_func(search_lan){
        this.filtered_city_list =  this.city_list.filter(
          city_object =>  city_object.city.toLowerCase().includes(search_lan.toLowerCase())
        )
    },
    search_category_func(search_category){
        this.filtered_category_list =  this.category_list.filter(
          category_object =>  category_object.category.toLowerCase().includes(search_category.toLowerCase())
        )
    },
    search_country_func(search_country){
        this.filtered_country_list =  this.country_list.filter(
          country_object =>  country_object.country.toLowerCase().includes(search_country.toLowerCase())
        )
    },
    load_country_func(search_item){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_countries'+'?search_item='+search_item,
        })
        .then(function(response){
            self.country_list = response.data
            self.filtered_country_list =self.country_list
        })
    },

    choose_category(category_object){
      this.category_list = this.category_list.map(category => {
        if (category.id == category_object.id){
            category.checked = category.checked === false ? true : false
        }
            return category
      })

      this.chosen_category_list = this.category_list.filter(function (category_obj) {
        if(category_obj.checked == true) {
          return category_obj
        }
      });


      // this.category_list = this.category_list.map(category => {
      //   if (category.id == category_object.id){
      //       category.checked = category.checked === false ? true : false
      //         category.changed= true
      //   }
      //       return category
      // })



    },
    choose_all_category(){
      if(!this.all_selected_category){
        this.all_selected_category = true
        this.category_list = this.category_list.map(category => {
              category.checked = true
              return category
        })
      }
      else{
        this.all_selected_category = false
        this.category_list = this.category_list.map(category => {
              category.checked = false
              return category
        })
      }
    },
    choose_country(country){
      var checked
      if(!country.checked){
        checked =true
        this.search_country = country.country
        this.chosen_country = [{"id":country.id,"country":country.country}]
      }
      else {
        checked=0
        this.chosen_country = []
      }
        for (let ii = 0; ii < this.country_list.length;ii++){
          this.country_list[ii].checked =  false
      }
      this.country_list[country.i].checked =  checked
    },
    add_more_to_product_list(){
      this.product_list_id = this.product_list_id + 1
      this.product_list.push(
      {
         'id':this.product_list_id,
         'list': [
           {"name":'', 'label':'Name', 'label_sve':'Namn'},
           {"name":'', 'label':'Description', 'descr_sve':'Beskrivning'}
          ]
      }
     )
    },
    check_email_exists(){
     var self= this;
     if (this.login_email != ''  ){
       axios({
          method:'get',
          url: API_URL+ '/register_check_email_username'+ '?email='+this.login_email,
        })
        .then(function(response){
          if (response.data =='denied'){
            self.$alert(" Email existerar redan, försök igen")
            }
          })
         }
         else{
              self.$alert("Antinen är Email tom eller har fel format")
         }
    },
    check_email_format(){
//    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re2 = /^w+[+.w-]*@([w-]+.)*w+[w-]*.([a-z]{2,4}|d+)$/i;

    if(this.login_email != ''){
      if (re2.test(this.email)){
        return "ggtrue"
      }
      else{
        return "ggfalse"
      }
    }
    else{
     return "vvtrue"
    }
  },
    remove_from_product_list(id){
      function removeItem(arr, id){
          return arr.filter(item => item.id !== id);
      }
      this.product_list = removeItem(  this.product_list, id)

    },

    post_to_S3_with_signed_url(response){
      var key ="enjordrepair_user"+response["company_id"] +"/" +  this.image
      var type =this.selectedFile.type

      var self = this
      var params = {
          Bucket: 'garemotest1',
          Key: key,
          "ContentType":type
      };

      self.get_AWS().getSignedUrl('putObject', params, function (err, url) {

      axios({
          method: "put",
          url,
          data: self.selectedFile,
          headers: {
              "Content-Type": type
          }
      })
      .then(() => {
          self.$store.commit('set_current_user', response)
          self.loading = false
          router.push({name:'profile'})

              //console.log('result', result);

          }).catch((err) => {
              console.log('err', err);
          });
      });
    },
    upload_picture(event){
              this.selectedFile = event.target.files[0]
              this.isActive=true
              setTimeout(() => {
                var image = document.getElementById('output');
                image.src = URL.createObjectURL(event.target.files[0]);
            }, 1000);


            var posttype_ending = this.selectedFile.type.split("/")[1]
            if (this.selectedFile.type.split("/")[1]  =='jpeg' && this.selectedFile.name.includes('jpg')){
              this.image=this.selectedFile.lastModified +this.selectedFile.name.split("jpg")[0]  + 'jpeg'
            }
            else{
              this.image=this.selectedFile.lastModified +this.selectedFile.name.split(posttype_ending.toString())[0]  + posttype_ending.toString()
            }

      }
  },

  mounted(){
    this.user_id =this.$store.state.current_user.user_id

    this.product_list =[
      {
         'id':1,
         'list': [
              {"name":'', 'label':'Name', 'label_sve':'Namn'},
              {"name":'', 'label':'Description', 'descr_sve':'Beskrivning'}
          ]
      }
    ]

   this.load_category("")
//    this.load_country_func("")
   this.load_cities()
   this.load_brands()


  }
}
</script>

<style lang="css" scoped>



body{
  font-family: sans-serif;
}

@media (max-width:450px ){
  .welcome{
    font-Weight:bold;
    font-Size:20px;
    line-height: normal;
    margin-Top:1%;
    height:50px;
    color: #0A6338
  }

  .create_account{
    height:70px;
    text-Align:center;
    width:98%;
    margin-Top:0%;
    font-Size:16px;
    margin-Left:0%;
    color:#0A6338
    }

    .register{
       margin-Top:3%;
       height:auto;
       text-Align:left;
       margin-Left:3%;
       width:94%
     }

     .register_header{
       width:95%;
       text-align: left;
       font-size:16px;
       margin-top: 6%;
       padding-top: 2%;
       margin-left: 2%;
       height:auto;
     }

    .information{
      font-weight: 600;
      color:#0A6338;
      font-Size:20px;
    }

    .information_location{
      height:auto;
      font-Size:13px;
      margin-Left:5%;
      color:black;
    }

    .box_main{
      width:98%;
      margin-Left: 2%;
      height:auto;
      margin-Top:8%;
      border-Radius:18px;
      background-Color:white;
      box-Shadow: 0px 3px 30px #0000001A;
      padding-Bottom:13px;
    }

    .box_main_image{
      width:98%;
      margin-Left: 2%;
      height:auto;
      margin-Top:2%;
      border-Radius:18px;
      background-Color:white;
      box-Shadow: 0px 3px 30px #0000001A;
      padding-Bottom:33px;
    }

    .information_small{
        margin-Left:4%;
        color:#0A6338;
        font-Size:18px;
        padding-Top:2%;
    }

    .lead_width_header {
      max-width: 180px;
      min-width: 180px;
      padding-left: 1%;
      height: 20px;
      font-size: 19px;
    }

    .search_city_v_for{
      height:20px;
      font-Size:15px;
      width:98%;
      display:flex;
    }



    .register_button{
      color:white;
      margin-left: 15%;
      margin-top: 4%;
      font-size: 20px;
      height:60px;
       width:60%;
       border-style:None;
        border-radius:8px;
        background-color: grey;
    }

    .register_button_clicked{
      color:white;
      margin-left: 15%;
      margin-top: 4%;
      font-size: 20px;
      height:60px;
       width:60%;
       border-style:None;
        border-radius:8px;
        background-color: #0A6338
    }

    .show_chosen_image_first{
     height: 40px;
      display: flex;
        border-radius: 8px;
      width: 40px;
      margin-left: 5%;
      object-fit: cover;
      position: absolute;
      background-color: white;

    }

    .addphoto_camera{
      height: 30px;
      width: 55px;
      margin-left: -23%;
      margin-top: 0%;
      position: absolute;
      cursor: pointer;
      /* background-color: #33D075; */
      color:black
    }

    .loading{
      width: 90%;
      background-Color:white;
      border-Radius: 67px;
      border: 5px solid #0A6338;
      color:#0A6338;
      z-Index:6;
      position:fixed;
      margin-Left:4%;
      font-Size:18px;
      padding-Top:5%;
      margin-Top:2%;
      height: 50%
    }

    .loading_outside{
      width: 100%;
      z-Index: 2;
      background-Color: black;
      opacity: 0.5;
      position:fixed;
      margin-Left:0%;
      margin-Top:0%;
      height: 100%;
    }



}


@media (min-width:450px ){
  .welcome{
    font-Weight:bold;
    font-Size:37px;
    margin-Top:3%;
    height:50px;
    color: #0A6338
  }

  .create_account{
      height:20px;
      text-Align:center;
      width:90%;
      margin-Top:0%;
      font-Size:18px;
      margin-Left:5%;
      color:#0A6338
  }

    .register{
       margin-Top:3%;
       height:auto;
       text-Align:left;
       margin-Left:12%;
       width:80%
     }

     .register_header{
       width:90%;
       text-align: left;
       font-size:16px;
       margin-top: 2%;
       padding-top: 2%;
       margin-left: 5%;
       height:auto;
     }

     .information{
       font-weight: 600;
       color:#0A6338;
       font-Size:24px;
     }

     .information_location{
       height:auto;
       font-Size:13px;
       margin-Left:4%;
       color:black;
     }


     .box_main{
       width:90%;
       margin-Left: 5%;
       height:auto;
       margin-top:5%;
       border-Radius:18px;
       background-Color:white;
       box-Shadow: 0px 3px 30px #0000001A;
       padding-Bottom:10px
     }

      .box_main_image{
        width:90%;
        margin-Left: 5%;
        height:auto;
        margin-Top:2%;
        border-Radius:18px;
        background-Color:white;
        box-Shadow: 0px 3px 30px #0000001A;
        padding-Bottom:40px
      }

     .information_small{
         margin-Left:4%;
         color:#0A6338;
         font-Size:18px;
         padding-Top:2%;
     }

     .lead_width_header {
       max-width: 190px;
       min-width: 190px;
       padding-left: 1%;
       height: 20px;
       font-size: 21px;
     }

     .search_city_v_for{
       height:20px;
       font-Size:15px;
       width:88%;
       display:flex;
     }


     .register_button{
       color:white;
       margin-left: 25%;
       margin-top: 2%;
       font-size: 22px;
       height:50px;
        width:40%;
        border-style:None;
         border-radius:8px;
         background-color: grey;
     }

     .register_button_clicked{

       color:white;
       margin-left: 25%;
       margin-top: 2%;
       font-size: 22px;
       height:50px;
        width:40%;
        border-style:None;
         border-radius:8px;
         background-color: #0A6338
     }

     .show_chosen_image_first{
      height: 70px;
       display: flex;
         border-radius: 8px;
       width: 70px;
       margin-left: 5%;
       object-fit: cover;
       position: absolute;
       background-color: white;
     }

     .addphoto_camera{
       height: 50px;
       width: 85px;
       margin-left: -23%;
       margin-top: 0%;
       position: absolute;
       cursor: pointer;
       /* background-color: #33D075; */
       color:black
     }

    .loading{
      width: 70%;
      background-Color:white;
      border-Radius: 67px;
      border: 5px solid #0A6338;
      color:#0A6338;
      z-Index:6;
      position:fixed;
      margin-Left:14%;
      font-Size:25px;
      padding-Top:5%;
      margin-Top:2%;
      height: 42%
    }

    .loading_outside{
      width: 100%;
      z-Index: 2;
      background-Color: black;
      opacity: 0.5;
      position:fixed;
      margin-Left:0%;
      margin-Top:0%;
      height: 100%;
    }


}




.lead_width_left {
  /* width: 200px; */
  max-width: 190px;
  min-width: 190px;
white-space: normal;
word-wrap: break-word;
  height: auto;
  font-size: 18px;
  padding-top: 0.5%;
 padding-left: 1%;
  /* border-left: 1px solid black; */
  text-align: left;
  /*padding-left: 2%; */
  /* border: 0.5px Solid; */
}

.lead_width {
  /* width: 200px; */
  max-width: 190px;
  min-width: 190px;
  white-space: normal;
  word-wrap: break-word;
  height: auto;
  font-size: 18px;
  padding-top: 0.5%;
  padding-left: 1%;

  /* border-left: 1px solid black; */
  text-align: left;
  /*padding-left: 2%; */
  /* border: 0.5px Solid; */
}

.input_field{
  border: 2px solid #59419B6F ;
  color:black;
  margin-top:1%;
  width:88%;
  margin-left: 4%;
   border-radius: 8px;

   height:29px;

}
.input_field_l{
  border: 2px solid #59419B6F ;
  color:black;
  margin-top:1%;
  width:88%;
  margin-left: 4%;
   border-radius: 8px;

   height:89px;

}




input{
    display: flex;
  width: 100%;
}

h2{
    display: flex;
     text-decoration: underline;
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width:0%;
}





.open_gear_box_outside{
  position: fixed;
  height: 1200px;
  opacity: 0.5;
  background: black;
  z-index: 2;
  width: 100%;
  left: 0px;
  top:0px;
}


.checkbox_new{
  margin-left: 1.5%;
  margin-top: 0%;
  /* border-radius:50%; */
  border-style:None;
  height: 18px;
  width: 18px;
  /* background-color: white; */
  /* position: absolute; */
  /* accent-color:white; */
}

.checkbox_filled{
  margin-left: 1.5%;
  margin-top: 0%;
  color: white;
  /* background-color: #33D075; */
  border-radius:50%;
  border-style:None;
  max-height: 18px;
  max-width: 18px;
  accent-color:#0A6338;
}


.search_ruta_input{
  margin-left: 4%;
  width: 88%;
  height: 24px;
  box-shadow: 0px 3px 12px #00000012;
  margin-top: 2%;
  border-radius: 8px;
  z-index: 4;
  border: 2px solid #59419B6F ;
}

.box_flow_key{
  border-radius: 50px;
  background-color: #0A6338;
  height: auto;
  color: white;
  padding-left: 1%;
  padding-right:  1%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-left: 1%;
  margin-top: 1%;
  font-size: 13px;
  display: inline-block;
  flex: none;
  box-sizing: border-box;
}



</style>
